@import "node_modules/bootstrap/scss/bootstrap";
@import 'node_modules/react-dual-listbox/src/scss/react-dual-listbox.scss';
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;600;700&family=Roboto&display=swap');

// stylelint-disable declaration-no-important

$purple: #4E11BD;
$razzamataz: #EC0868;
$scarlet: #FC2F00;
$tangerine: #EC7D10;
$gold: #FFBC0A;

body {
  /*padding-top: 5rem;*/
  background-color: #0f0f0f;
  font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}

h1 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  font-size: 35px;
}

h2 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;

}

h3 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
}

h4 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
}

.starter-template {
  /*padding: 3rem 1.5rem;*/
  text-align: center;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.navbar-ma {
  background-color: #18181b!important;
  box-shadow: 0 3px 3px rgba(0,0,0,.2),0 0 2px rgba(0,0,0,.1)!important;
  //border-bottom: 7px solid #d78d0d;
}

.dropdown-menu .dropdown-item-text {
  color: #545b62!important;
  font-size: 1.2em;
  font-weight: '600';
}

main {
  padding-left: 0;
}

.ma-herobg {
  padding-top: 3rem;
  height: 800px;
  background-color:#333;
}

.ma-heroc {
  padding-bottom: 3rem;
}

.ma-herod {
  text-shadow: 2px 2px 5px #000;
  line-height: 1.7;
}

.ma-herobtn {
  margin: 0px 10px;
}

.ma-herobtnbg {
  background-color: #4E11BD!important;
  border-color: #4A0EB6!important;
}

.nav-link.active{
  background-color: #4E11BD!important;
  border-color: #4A0EB6!important;
}



.ma-nav .nav-item {
  margin: 0px 5px;
  font-size: 0.8rem;
}

.ma-nav .nav-link {
  color: rgba(255,255,255,1)!important;
}

header.ma-front {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header.ma-article {
	background-color:none!important;
	height: none!important;
	min-height: 0px!important;
}

article.type-post  {
  padding-bottom: 5rem;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.ma-card-bg {
  background-color: #18181b!important;
  color: #efeff1!important;
  /*border: 1px solid rgba(0,0,0,.4);
  border-radius: .6rem!important;
  box-shadow: 0 3px 3px rgba(0,0,0,.2),0 0 2px rgba(0,0,0,.1)!important;*/
}

#play {
  cursor: pointer;
  width:80px;
  height:80px;
  background-size: cover;
  color:#fff;
  right: 2rem;
  top: 6rem;
  position: absolute;
  z-index: 1;
}

.avatar-25 {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
	padding: 2px;
	background-color: #111;
}

.dropdown-menu {
	background-color:#18181b!important;
	font-size: 0.8rem;
}

.dropdown-menu .dropdown-item {
	color: #fff!important;
}

.dropdown-menu .dropdown-item:focus,.dropdown-menu .dropdown-item:hover {
	background-color: #4E11BD!important;
}

.dropdown-divider {
	border-top: 1px solid #101010!important;
}

.ma-dash {
	margin-top: 4rem;
  padding-bottom: 5rem;
}

table td, table th {
	border-top: 1px solid #101010!important;
}

table thead th {
	border-bottom: 0px!important;
}



#trn ladder-standings-table_filter .input {
	background-color:#0e0e10!important;
	color: #fff!important;
}

.trn-ladder-standings-table, .trn-scheduled-matches-table, .trn-player-team-table, .trn-ladder-matches-table, .trn-ladder-competitions-table, .trn-tournament-competitions-table, .trn-match-history-table, .trn-players-table, .trn-teams-table, .trn-challenges-table, .trn-matches-table, .trn-report-results-table, .trn-confirm-results-table {
	color: #fff;
	font-size: 0.8rem;
}

.trn-delete-match-action {
	background-color: red!important;
}

#trn-team-roster-table {
	color: #fff!important;
	font-size: 0.8rem!important;
}

.modal-content {
	background-color: #333!important;
}

li .nav-link {
	color: #fff!important;
}

.nav-pills .nav-link.active {
	background-color: #4E11BD!important;
}

#trn .trn-delete-challenge-button {
	background-color: red!important;
}

#trn .btn {
	color: #fff!important;
	background-color: #4E11BD;
}

#trn .btn-primary {
	border-color:#000;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4E11BD!important;
    border-color: #4E11BD!important;
}

.ma-challenge-img {
	width: 100%;
}

#trn .form-group #challengee, #trn .form-group #challenger_pick, #trn .form-group #challenger_pick_one, #trn .form-group #challenger_pick_two, #trn .form-group #challenger_pick_three, #trn .form-group #match_time_picker, #trn .form-group #comment, #trn .form-group #lid {
	background-color:#0e0e10;
	color:#fff;
}

#trn .card {
	background-color:#18181b!important;
}

#trn .dataTables_length select, #server-list_wrapper .dataTables_length select, #trn .dataTables_filter input, #trn input, #server-list_wrapper input, #trn select, #server-list_wrapper select {
	background-color: #101010;
	border-color: #0c0c0c;
	color: #ccc;
}

#trn .pagination li .page-link, #server-list_wrapper .pagination li .page-link {
	background-color: #101010;
	border-color: #0c0c0c;
	color: #ccc;
}

#trn .pagination .disabled .page-link, #server-list_wrapper .pagination .disabled .page-link {
	color: #4e4e4e!important;
}

#trn option:checked, #trn option:hover {
	background-color: #4E11BD;
}

#trn .btn-secondary {
	border-color: #4A0EB6!important;
}

#trn #ladders .item-avatar {
	display: none;
}

#trn #roster h4, #trn #competitions h4, #trn #match-history h4, #trn #challenges h4 {
	padding: 1.5rem 0;
}

#trn #trn-edit-player-profile-form textarea, #trn #trn-edit-player-profile-form checkbox {
	background-color: #101010;
	border-color: #0c0c0c;
	color: #ccc;
}

#trn #trn-edit-player-profile-form .form-control-file {
	background-color: #18181b!important;
}

#recent-posts-7 .widget-title {
  display: none;
}

#recent-posts-7 .post-date {
  display: block;
  font-size: 0.8rem;
  color: #656565;
}

#recent-posts-7 ul {
  margin-bottom: 0px;
}

.ma-dashboard li {
  list-style-type: none;
}

.ma-dashboard ul {
  padding-left: 0px;
}

.ma-dashboard .widget-title {
  font-size: 1rem;
}

.ma-dashboard .table td, .ma-dashboard .table th {
  padding: .3rem;
}

.ma-dash-r, .ma-dashTR {
	float: right;
}

#trn .pull-right {
  float: right;
}

#trn .news-icon {
  float: left;
  margin-right: 10px;
}

.card-divider {
	border-top: 1px solid rgba(0,0,0,.125);
}

#trn .trn-my-competitions-table {
  color: #efeff1;
}

#twitch-embed {
  height: -webkit-calc(100vh - 91px);
  height: -moz-calc(100vh - 91px);
  height: calc(100vh - 91px);
  width: 100%;
  margin-top: 3.5rem;
  margin-bottom: 2.2rem;
  overflow: hidden;
}

.ma-stream .nav-item a {
  color: #efeff1;
}

.ma-stream .nav-item .viewers {
  color: #888888;
}

.ma-tooltip {
  position: relative;
  display: inline-block;
}

.ma-tooltip .ma-tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.ma-tooltip .ma-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.ma-tooltip:hover .ma-tooltiptext {
  visibility: visible;
}

@media (pointer: coarse) and (hover: none) {
  header video {
    display: none;
  }
}

#buyButton, #buyButton:hover {
  color: #fff!important;
}

.item-text-shadow {
  text-shadow: 0 0 3px #000;
}

.list-group-item a:hover {
  color: #fff!important;
  text-decoration: none!important;
}

.type-post .entry-content {
  padding-top: 2rem;
}

.type-post .updated {
  display: none;
}

.type-post .published {
  display: inline!important;
}

.ma-blog-sidebar img {
  width:25px;
  height:25px;
}

.ma-blog-sidebar .nav-link {
  padding: .5rem .7rem !important;
}

.ma-av {
  -ms-flex: 0 0 75px;
    flex: 0 0 75px;
}

a {
  color: #fff;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

.modal-content {
  background-color: #18181b!important;
  color: #efeff1!important;
  border: 1px solid rgba(0,0,0,.4);
  border-radius: .6rem!important;
  box-shadow: 0 3px 3px rgba(0,0,0,.2),0 0 2px rgba(0,0,0,.1)!important;
}
.form-control, .form-control:focus {
  background-color: #101010;
  border-color: #0c0c0c;
  color: #ccc;
}

.bundle-card {
  background-color: #18181b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
  color: #fff;
  /*padding: 5px;*/
  margin-bottom: 30px;
  /*min-height: 200px;*/
  display: flex;
  flex-direction: column;
  /*justify-content: space-around;*/
  position: relative;
}

.owned {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 1rem;
}

.price {
  color: #fff;
  font-size: 1rem;
}

.bundle-card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
}

.bundle-card button:hover {
  opacity: 0.7;
}

.bundle-card h3 {
  margin-top: 15px;
	font-size: 1.1rem;
}

.buy-modal {
  width: 938px;
  height: 408px;
}

.modal-text {
  background-color: #fff;
}

.list-group-item {
  background-color: #18181b !important;
  color: #fff !important;
	padding: .75rem .75rem .75rem 1.75rem;
  border-color: #2b2b2b;
  border-width: 1px;
}

.purple {
  color: $purple;
}

.white {
  color: #fff;
}

.btn-large {
  width: 200px;
  height: 50px;
}

.btn-purple {
  background-color: $purple;
  color: #fff;
}

.btn-danger {
  background-color: $scarlet;
  color: #fff;
}

.btn:hover { color: #fff !important; }

.btn-gold { background-color: $tangerine; color:#fff; }

.list-group-item.btn-purple {	background-color: #4E11BD !important; }

.btn-gold:hover, .btn-gold:focus { background-color: $tangerine; color: #fff !important; }
.btn-purple:hover, .btn-purple:focus { background-color:#5f1ed6;  }


.search {
  padding: 0 !important;
}

.card-image-overlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bundle-card .card-image-overlay:hover {
  /*outline: 3px solid #4E11BD;*/
	/*box-shadow: rgb(30, 255, 0) 0px 0px 5px 3px;*/
  
}

.itemdesc-box {
  background: rgba(0, 0, 0, 0.699);
  position: relative;
  width: 100%;
  bottom: 0;
  padding: 0.5rem;
}

.card-title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.pagination .disabled .page-link, #item-list_wrapper .pagination .disabled .page-link {
  color: #4e4e4e!important;
}

.pagination li .page-link, #item-list_wrapper .pagination li .page-link {
  background-color: #101010;
  border-color: #0c0c0c;
  color: #ccc;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.form-check-label {
  background-color: #18181b !important;
  color: #fff;
  font-size: 0.9em;
}

/* rarity border colors */
.bundle-card .common { 
  /*box-shadow: rgb(255, 255, 255) 0px 0px 5px 3px; */
  background-color: rgb(85, 85, 85);
}
.bundle-card .rare { 
  /*box-shadow: rgb(0, 112, 221) 0px 0px 5px 3px; */
  background-color: rgb(0, 112, 221) ;
}
.bundle-card .epic { 
  /*box-shadow: rgb(163, 53, 238) 0px 0px 5px 3px; */
  background-color: rgb(163, 53, 238);
}
.bundle-card .legendary { 
  /*box-shadow: $tangerine 0px 0px 5px 3px; */
  background-color: $tangerine;
}

/* rarity border colors */
.subcommon { 
  /*box-shadow: rgb(255, 255, 255) 0px 0px 5px 3px; */
  background-color: rgb(43, 43, 43);
}
.subrare { 
  /*box-shadow: rgb(0, 112, 221) 0px 0px 5px 3px; */
  background-color: rgb(1, 86, 172) ;
}
.subepic { 
  /*box-shadow: rgb(163, 53, 238) 0px 0px 5px 3px; */
  background-color: rgb(119, 35, 175);
}
.sublegendary { 
  /*box-shadow: $tangerine 0px 0px 5px 3px; */
  background-color: #b3600e;
}

/* rarity border colors */
.cardcommon:hover { 
  /*box-shadow: rgb(255, 255, 255) 0px 0px 5px 3px; */
  outline: 3px solid rgb(150, 150, 150);
}
.cardrare:hover { 
  /*box-shadow: rgb(0, 112, 221) 0px 0px 5px 3px; */
  outline: 3px solid rgb(13, 100, 172);
}
.cardepic:hover { 
  /*box-shadow: rgb(163, 53, 238) 0px 0px 5px 3px; */
  outline: 3px solid rgb(95, 17, 126);
}
.cardlegendary:hover { 
  /*box-shadow: $tangerine 0px 0px 5px 3px; */
  outline: 3px solid rgb(201, 98, 14) ;
}


/* owned icon */
.owned-icon {
	position: absolute;
	top: 10px;
	right: 15px;
}

@media (max-width: 991px) {
  .bundle-card h3,.bundle-card p {
    font-size: 0.7rem;
  }
}

@media (max-width: 575px) {
  .bundle-card h3,.bundle-card p {
    font-size: 1rem;
  }
}

.aligncenter {
  clear: both;
}

.wp-block-embed__wrapper {
  position: relative;
  height: 640px;
}

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wp-block-embed iframe {
  max-width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4E11BD;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4E11BD;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.alert-success {
  background-color: #ffb700 !important;
  color: #fff !important;
  border-color: #ffb700 !important;
}

.scrollbar {
  height: 300px;
  width: 50%;
  overflow: auto;
  padding: 0 10px;
}
/*       ScrollBar 1        */

#scrollable::-webkit-scrollbar {
  width: 10px;
}

#scrollable::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #0e0e10;
}

#scrollable::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $purple;
}

.credits-icon {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 2px;
}

.table {
  color: #fff !important;
}

.firstrow {
  border: none !important;
  text-align: center;
  font-weight: 800;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #18181b !important;
}

input[type='checkbox'] {
  accent-color: $purple;
}

a {
  color: #007bff;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0!important;
  color: #007bff;
  cursor: pointer;
}

.submit-btn-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}




.card-body{
  padding-right:3em;
  padding-left:3em;
  padding-top:2em;
  padding-bottom:2em;
}

.card-header{
  padding-top:1.5em;
  color:rgb(255, 255, 255);
  
  background-color: #40286d;
  background-image: url("bgimage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

p{
  line-height:1.3;
  margin-top:25px;
  margin-bottom: 25px;
}

#pagination-container{
  background-color: rgb(16, 16, 16);
  font-size: 1rem;
  padding: 10px;
  border-radius:10px;
}

select#perPage{
  border:none;
}

#trn-ladder-title{
  background-color: #4E11BD;
  padding: 10px;
  text-align: center;
}

#hallOfFame{
  background-color:#4E11BD;
}

.btn-small{
  font-size:0.8rem;
}


.cover{
  height:100%;
  width:100%;
  background-color:rgba(33, 25, 37, 0.5);
  z-index:15;
  position:absolute;
}

.bundle-card:hover{
  /*outline: 2px solid rgb(30, 255, 0) ;*/
  
}

.btn-green {
  background-color: #21703c;
  color: #fff;
}
.btn-green:hover {
  background-color: #19522d;
}

